import React from 'react';
import { Alert } from 'reactstrap';
import { connect } from 'react-redux';
import store from '../store';
import { toggleAction } from '../actions/alertActions';


class CustomAlert extends React.Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.toggleAlert = this.toggleAlert.bind(this);
  }
  handleClose() {
    this.props.toggleAction(false);
  }

  toggleAlert(visible) {
    if (visible) {
      const tog = this.props.toggleAction;
      setTimeout(() => { tog(false); }, 5000);
    }
  }

  render() {
    const { alert } = store.getState();
    return (
      <Alert
        color={alert.alertType}
        isOpen={alert.visible}
        toggle={this.handleClose}
      >
        {alert.content}
        {this.toggleAlert(alert.visible)}
      </Alert>
    );
  }
}
const mapStateToProps = state => ({
  visible: state.alert.visible,
});

const mapDispatchToProps = dispatch => ({
  toggleAction: (bool) => {
    dispatch(toggleAction(bool));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomAlert);
