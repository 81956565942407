export const LOGIN_CASE = 'LOGIN_CASE';
export const CHECK_JWT_CASE = 'CHECK_JWT_CASE';

export function loginAction(element, bool) {
  return {
    type: LOGIN_CASE,
    token: element,
    logged: bool,
  };
}

export function loggedAction(element, bool) {
  return {
    type: CHECK_JWT_CASE,
    token: element,
    logged: bool,
  };
}
