import React, { useContext, useState, createContext } from "react";

export const ParametersContext = createContext(null);

export const ParametersProvider = ({ children }) => {
  const [filters, setFilters] = useState(['', '', '', '', [], '', '', '', '']);
  return (
    <ParametersContext.Provider value={{ filters, setFilters }}>
      {children}
    </ParametersContext.Provider>
  );
};

export const useParameters = () => {
  const context = useContext(ParametersContext);
  if (!context) {
    throw new Error(
      "Components using ParametersContext must be rendered within the Parameters Provider"
    );
  }

  return context;
};

export default ParametersProvider;