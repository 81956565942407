const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    minHeight: '100%',
    maxWidth: theme.breakpoints.values.xl,
    '&>*:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  buttonRow: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    '&>*': {
      flexShrink: 1,
      marginBottom: theme.spacing(1),
      '&:not(:last-child)': {
        marginRight: theme.spacing(2),
      },
    },
  },
  buttonColumnCentered: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  buttonRowCentered: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    '&>*': {
      flexShrink: 1,
      marginBottom: theme.spacing(1),
      '&:not(:last-child)': {
        marginRight: theme.spacing(2),
      },
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  inputButton: {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: '100%',
    opacity: 0,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  row: {
    display: 'flex',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    padding: theme.spacing(6),
  },
});

export default styles;
