import React from 'react';
import { connect } from 'react-redux';
import { post, get } from 'axios';
import withStyles from '@mui/styles/withStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { AUTH_TOKEN } from '../../../constants/authConstants';
import logo from '../../../assets/logo.png';
import { loginAction } from '../../../actions/authActions';
import { userAction } from '../../../actions/userActions';
import { sessionAction } from '../../../actions/sessionActions';
import { createAction } from '../../../actions/alertActions';
import { handleGetResponse, handlePostResponse, handleError } from '../../../utils/handleRequests';
import { authPayload, tokenPayload } from '../../../utils/requestsPayloads';
import { CURRENT_URL, USER_TOKEN_URL, FORGOT_PASSWORD_URL } from '../../../constants/apiURLs';
import './form.css';
import styles from '../../../styles';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      forgotEmail: '',
      submitting: false,
      error: false,
      forgot: false,
      sent: false,
    };
    this.handleGetResponse = handleGetResponse.bind(this);
    this.handlePostResponse = handlePostResponse.bind(this);
    this.handleError = handleError.bind(this);
  }

  handleSendMail = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('email', this.state.forgotEmail);
    post(FORGOT_PASSWORD_URL, formData);
    this.setState({ forgot: false, sent: true });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ submitting: true, error: false });
    post(USER_TOKEN_URL, tokenPayload(this.state.email, this.state.password))
      .then(response => this.handlePostResponse(response, AUTH_TOKEN))
      .then((tok) => {
        get(CURRENT_URL, authPayload(tok))
          .then((response) => {
            this.handleGetResponse(response);
            let init_path = '/home/'
            this.props.history.push({
              pathname: init_path,
              state: { token: tok },
            });
          })
          .catch((e) => {
            this.handleError(e);
            this.props.createAction('danger', 'Ocurrió un error inesperado. Por favor revisa tu conexión a internet.', true);
            this.setState({ submitting: false });
          });
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          this.props.createAction('danger', 'Correo electrónico o contraseña incorrecta.', true);
        } else {
          this.props.createAction('danger', 'Ocurrió un error inesperado. Por favor revisa tu conexión a internet.', true);
          // eslint-disable-next-line
          console.log(e);
        }
        this.setState({ submitting: false, error: true });
      });
  }

  handleChange = name => event => this.setState({
    [name]: event.target.value,
  });

  render() {
    if (this.state.forgot) {
      return (
        <div className="Login_container">
          <Card>
            <form
              className="Login_form"
              onSubmit={this.handleSendMail}
            >
              <CardContent>
                <img
                  className="Login_logo"
                  alt="SummaPartners"
                  src={logo}
                />
                <TextField
                  id="email"
                  label="Correo electrónico"
                  name="email"
                  value={this.state.forgotEmail}
                  onChange={this.handleChange('forgotEmail')}
                  margin="normal"
                  color="primary"
                  fullWidth
                  autoFocus
                  required
                  error={this.state.error}
                />
                <div>
                  <Button
                    variant="outlined"
                    color="secondary"
                    className={this.props.classes.button}
                    onClick={() => this.setState({ forgot: false })}
                  >
                    Volver
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className={this.props.classes.button}
                    type="submit"
                    disabled={this.state.submitting}
                  >
                    Enviar
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      );
    }
    return (
      <div className="Login_container">
        <Card>
          <form
            className="Login_form"
            onSubmit={this.handleSubmit}
          >
            <CardContent>
              <img
                className="Login_logo"
                alt="SummaPartners"
                src={logo}
              />
              <TextField
                id="email"
                label="Correo electrónico"
                name="email"
                value={this.state.email}
                onChange={this.handleChange('email')}
                margin="normal"
                color="primary"
                fullWidth
                autoFocus
                required
                error={this.state.error}
              />
              <TextField
                id="password"
                label="Contraseña"
                name="password"
                value={this.state.password}
                type="password"
                onChange={this.handleChange('password')}
                margin="normal"
                color="primary"
                fullWidth
                required
                error={this.state.error}
              />
              <div className={this.props.classes.buttonColumnCentered}>
                <Button
                  variant="contained"
                  color="primary"
                  className={this.props.classes.button}
                  type="submit"
                  disabled={this.state.submitting}
                >
                  {this.state.submitting ? 'Iniciando sesión' : 'Iniciar sesión'}
                </Button>
                <Button
                  size="small"
                  color="secondary"
                  className={this.props.classes.button}
                  onClick={() => this.setState({ forgot: true })}
                  disabled={this.state.sent}
                >
                  {this.state.sent ? 'Enviado' : 'Olvidé mi contraseña'}
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.auth.token,
  logged: true,
  id: state.user.id,
  username: state.user.username,
  role: state.user.role,
  alertType: state.alert.alertType,
  content: state.alert.content,
  visible: state.alert.visible,
  sessionType: state.user.sessionType,
});

const mapDispatchToProps = dispatch => ({
  loginAction: (token, bool) => {
    dispatch(loginAction(token, bool));
  },
  createAction: (alertType, content, bool) => {
    dispatch(createAction(alertType, content, bool));
  },
  userAction: (response) => {
    dispatch(userAction(response));
  },
  sessionAction: (sessionType) => {
    dispatch(sessionAction(sessionType));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Login));
