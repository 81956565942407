import React from "react";
import { PDFObject } from 'react-pdfobject'
import { Grid, Paper } from "@mui/material";
import knowledge_map from "../../assets/Mapa del Conocimiento vs01.pdf"

const Home = () => {
    return (
        <Grid
            container
            style={{ justifyContent: 'center', alignItems: 'center' }}
        >
            <Grid item xs={11} style={{ height: '1000px' }}>
                <Paper style={{ padding: 10, marginTop: 20 }}>
                    <PDFObject url={knowledge_map} height='950px'
                    pdfOpenParams={{ view: 'Fit' }}
                     />
                </Paper>
            </Grid>
        </Grid>
    )
}

export default Home;