import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { withRouter } from 'react-router-dom';
import { get } from 'axios';
import store from '../../store';
import VisitHome from '../login';
import SessionApi from '../login/api';
import CustomAlert from '../../components/customAlert';
import { AUTH_TOKEN } from '../../constants/authConstants';
import { CURRENT_URL } from '../../constants/apiURLs';
import { authPayload } from '../../utils/requestsPayloads';
import { loggedAction } from '../../actions/authActions';
import { userAction } from '../../actions/userActions';
import { handleGetResponse, handleError } from '../../utils/handleRequests';


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.handleGetResponse = handleGetResponse.bind(this);
    this.handleError = handleError.bind(this);
  }

  UNSAFE_componentWillMount() {
    const jwt = localStorage.getItem(AUTH_TOKEN);

    if (!jwt) { return <Redirect to="/" />; } // guard-clause.

    this.setState({ loading: true });
    SessionApi.validUser(jwt)
      .then(() => {
        this.setState({ loading: false });
        this.props.loggedAction(jwt, true);
      })
      .then(() => {
        get(CURRENT_URL, authPayload(jwt))
          .then((response) => {
            this.handleGetResponse(response);
          })
          .catch((e) => {
            this.handleError(e);
            return <Redirect to="/" />;
          });
      })
      .catch(() => {
        this.setState({ loading: false });
        localStorage.removeItem(AUTH_TOKEN);
        return <Redirect to="/" />;
      });
  }

  render() {
    const { auth } = store.getState();
    if (this.state.loading) {
      return <div />;
    }
    if (!auth.logged) {
      return (
        <div style={{ height: '100vh', display: 'flex', flexDirection: 'column'  }}>
          <CustomAlert />
          <VisitHome {...this.props} />
        </div>
      );
    }
    return (
      <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
        <CustomAlert />
        {this.props.children}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.auth.token,
  logged: state.auth.logged,
  user: store.getState().user,
});

const mapDispatchToProps = dispatch => ({
  loggedAction: (token, bool) => {
    dispatch(loggedAction(token, bool));
  },
  userAction: (response) => {
    dispatch(userAction(response));
  },
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(App));
