import { LOGIN_CASE, CHECK_JWT_CASE } from '../actions/authActions';

function auth(state = {}, action) {
  switch (action.type) {
  // saves the token into the state
  case LOGIN_CASE:
    return {
      ...state,
      token: action.token,
      logged: action.logged,
    };
  case CHECK_JWT_CASE:
    return {
      ...state,
      token: action.token,
      logged: action.logged,
    };
  // as always, on default do nothing
  default:
    return {
      ...state,
    };
  }
}

export default auth;
