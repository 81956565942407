export const CREATE_CASE = 'CREATE_CASE';
export const TOGGLE_CASE = 'TOGGLE_CASE';

export function createAction(alertType, content, bool) {
  return {
    type: CREATE_CASE,
    alertType,
    content,
    visible: bool,
  };
}

export function toggleAction(bool) {
  return {
    type: TOGGLE_CASE,
    visible: bool,
  };
}
