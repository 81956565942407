import { SESSION_CHANGED } from '../actions/sessionActions';

function session(state = {}, action) {
  switch (action.type) {
  // saves the user into the state
  case SESSION_CHANGED:
    return {
      ...state,
      sessionType: action.sessionType,
    };

  // as always, on default do nothing
  default:
    return {
      ...state,
    };
  }
}

export default session;
