import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import authReducer from './authReducer';
import alertReducer from './alertReducer';
import userReducer from './userReducer';
import sessionReducer from './sessionReducer'

export default combineReducers({
  routing: routerReducer,
  auth: authReducer,
  alert: alertReducer,
  user: userReducer,
  session: sessionReducer,
});
