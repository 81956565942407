import { createTheme, adaptV4Theme } from '@mui/material/styles';
import { grey, red } from '@mui/material/colors';

const primaryColor = '#014169';
const primaryContrastColor = '#FFF';

const theme = createTheme(adaptV4Theme({
  palette: {
    common: {
      red: red[900],
    },
    primary: {
      main: primaryColor,
      contrastText: primaryContrastColor,
      backgroundColor: "FFF"

    },
    secondary: {
      main: '#F00',
      contrastText: primaryContrastColor,
      backgroundColor: '#FFF',
    },
    error: {
      main: red[600],
    },
    paper: {
      backgroundColor: primaryColor,
      color: primaryContrastColor,
    },
    contrast: {
      main: primaryContrastColor
    }
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        color: primaryContrastColor,
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: primaryColor,
        color: primaryContrastColor,
      },
    },
    MuiGrid: {
      container: {
        boxSizing: 'border-box'
      }
    },

  },
}));

export default theme;
