import { AUTH_TOKEN } from '../constants/authConstants';

export const authPayload = (token) => {
  const auth = token || localStorage.getItem(AUTH_TOKEN);
  return {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  };
};

export const authDownloadPayload = (token) => {
  const auth = token || localStorage.getItem(AUTH_TOKEN);
  return {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
    responseType: 'arraybuffer'
  };
};

export const authPostPayload = (token) => {
  const auth = token || localStorage.getItem(AUTH_TOKEN);
  return {
    headers: {
      Authorization: `Bearer ${auth}`,
      'Content-Type': 'application/json',

    },
  };
};

export const tokenPayload = (mail, pass) => ({
  auth: {
    email: mail,
    password: pass,
  },
});

export const selectPayload = (secondHierarchyId, videoId) => ({
  select: {
    second_hierarchy_id: secondHierarchyId,
    video_id: videoId,
  },
});

export const seriesSelectPayload = (secondHierarchyId, serieId) => ({
  series_select: {
    second_hierarchy_id: secondHierarchyId,
    serie_id: serieId,
  },
});

export const assignedVideoPayload = (videoId, userId) => ({
  assigned_video: {
    video_id: videoId,
    user_id: userId,
  },
});

export const assignedSeriePayload = (serieId, userId) => ({
  assigned_serie: {
    serie_id: serieId,
    user_id: userId,
  },
});

export const assignedClipPayload = (clipId, userId) => ({
  assigned_clip: {
    clip_id: clipId,
    user_id: userId,
  },
});

export const assignedCategoryPayload = (videoId, categoryId) => ({
  assigned_category: {
    video_id: videoId,
    category_id: categoryId,
  },
});

export const assignedSerieCategoryPayload = (serieId, categoryId) => ({
  assigned_serie_category: {
    serie_id: serieId,
    category_id: categoryId,
  },
});

export const replicateAssignationFHierarchy = secondHierarchyId => ({
  first_hierarchy: {
    second_hierarchy_id: secondHierarchyId,
  },
});

export const videoAssignationFHierarchy = videoId => ({
  first_hierarchy: {
    video_id: videoId,
  },
});

export const serieAssignationFHierarchy = serieId => ({
  first_hierarchy: {
    serie_id: serieId,
  },
});
