import { CU_CASE, SESSION_TYPE_CHANGED } from '../actions/userActions';

function user(state = {}, action) {
  switch (action.type) {
  // saves the user into the state

  case CU_CASE:
    return {
      ...state,
      id: action.id,
      username: action.username,
      role: action.role,
      email: action.email,
      consultant_id: action.consultant_id,
      consultant: action.consultant,
      sessionType: action.sessionType,
    };

  case SESSION_TYPE_CHANGED:
    return {
      ...state,
      sessionType: action.sessionType,
    };

  // as always, on default do nothing
  default:
    return {
      ...state,
    };
  }
}

export default user;
