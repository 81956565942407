import { CREATE_CASE, TOGGLE_CASE } from '../actions/alertActions';

function alert(state = {}, action) {
  switch (action.type) {
  case CREATE_CASE:
    return {
      ...state,
      type: action.type,
      alertType: action.alertType,
      content: action.content,
      visible: action.visible,
    };
  case TOGGLE_CASE:
    return {
      ...state,
      type: action.type,
      visible: action.visible,
    };
  default:
    return {
      ...state,
    };
  }
}
export default alert;
