import React from 'react';
import Login from './components/form';
import './index.css';

const VisitHome = props => (
  <div className="VisitHome">
    <Login {...props} />
  </div>
);

export default VisitHome;
