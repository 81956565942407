import React from 'react';
import { get } from 'axios';
import { AUTH_TOKEN } from '../../../constants/authConstants';
import { AUTH_URL } from '../../../constants/apiURLs';

class SessionApi extends React.Component {
  static validUser(token) {
    // TODO: Implement check if user is valid
    return new Promise((resolve, reject) => {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
      get(AUTH_URL, { headers })
        .then((response) => {
          if (response.status === 200) {
            resolve(true);
          } else {
            localStorage.removeItem(AUTH_TOKEN);
            reject();
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default SessionApi;
