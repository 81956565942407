export function handleGetResponse(response) {
  if (response.status === 200) {
    this.props.userAction(response.data);
    return response.data;
  }
  this.props.createAction('danger', 'Hubo un problema muy grave. Contacte el equipo de SummaAnalytics', true);
  return null;
}



export function handleGetResponseFirst(response) {
  if (response.status === 200) {
    let sessionType = 'km_session'
    if (response.data.role === 'staffing'){
      sessionType = 'staffing'
    }
    console.log(sessionType)
    this.props.firstUserAction(response.data, sessionType);
    return response.data;
  }
  this.props.createAction('danger', 'Hubo un problema muy grave. Contacte el equipo de SummaAnalytics', true);
  return null;
}


export function handlePostResponse(response, AUTH_TOKEN) {
  if (response.status === 201) {
    this.props.loginAction(response.data.jwt, true);
    localStorage.setItem(AUTH_TOKEN, response.data.jwt);
    return response.data.jwt;
  }
  this.props.createAction('danger', 'Correo o clave incorrecta', true);
  return null;
}

export function handleError() {
  return 'ERROR src/utils/handleRequest.js 21:3 :+1:';
}
