const AUTH_TOKEN = 'AUTH_TOKEN';

const HEADERS = {
  headers: {
  },
};

const POST_HEADERS = {
  headers: {
    'Content-Type': 'application/json',

  },
};

export { AUTH_TOKEN, HEADERS, POST_HEADERS };
