export const CU_CASE = 'CU_CASE';
export const SESSION_TYPE_CHANGED = 'SESSION_TYPE_CHANGED'

export function userAction(element) {
  return {
    type: CU_CASE,
    id: element.id,
    username: element.username,
    role: element.role,
    email: element.email,
    consultant_id: element.consultant_id,
    consultant: element.consultant,
    sessionType: element.session_type,
  };
}


export function togleSessionType(sessionType) {
  return {
    type :SESSION_TYPE_CHANGED,
    sessionType: sessionType
  }
}
