import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import rootReducer from '../reducers/index';

export const history = createBrowserHistory();

const initialState = {
  routing: {},
  auth: {
    token: '',
    logged: false,
  },
  alert: {
    alertType: 'danger',
    content: '',
    visible: false,
  },
  user: {
    id: -1,
    username: '',
    role: -1,
    email: '',
    consultant_id: '',
    consultant: '',
    sessionType: '',
  },
  session: {
    sessionType: '',
  }
};
const enhancers = [];
const middleware = [
  thunk,
  routerMiddleware(history),
];
if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers,
);

const store = createStore(
  rootReducer,
  initialState,
  composedEnhancers,
);
export default store;
